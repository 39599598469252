/*eslint-disable*/

const CustomHelpers = {

    install(Vue, options) {
        Vue.prototype.$authenticatedUser = function () {
            return JSON.parse(localStorage.getItem('user'));
        }
        Vue.prototype.$parseJwt = function (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        }
        Vue.prototype.$returnAuthenticatedUserRole = function () {
            return this.$parseJwt(this.$authenticatedUser().token).roleId;
        }
        Vue.prototype.$isAdmin = function () {
            return this.$parseJwt(this.$authenticatedUser().token).roleId == 1;
        }
    }
};

export default CustomHelpers;

