import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },

    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },

        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/investors',
        name: 'investors',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/investors'),
    },
    {
        path: '/investors/new',
        name: 'new-investor',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/investors/new'),
    },
    {
        path: '/investors/:id',
        name: 'investor-detail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/companies/detail'),
    },
    {
        path: '/investors/:id/edit',
        name: 'investor-edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/investors/edit'),
    },
    {
        path: '/companies',
        name: 'companies',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/investors')
    },
    {
        path: '/companies/new',
        name: 'new-company',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/investors/new'),
    },
    {
        path: '/companies/:id',
        name: 'company-detail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/companies/detail'),
    },
    {
        path: '/companies/:id/edit',
        name: 'company-edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/investors/edit'),
    },
    {
        path: '/companies/:id/surveys/:eventId',
        name: 'event-surveys',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/companies/eventSurveys'),
    },
    {
        path: '/events',
        name: 'events',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/investors')
    },
    {
        path: '/events/new',
        name: 'new-event',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/events/new')
    },
    {
        path: '/events/:id',
        name: 'event-detail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/companies/detail')
    },
    {
        path: '/events/:id/edit',
        name: 'event-edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/events/edit')
    },
    {
        path: '/rsvp/:id',
        name: 'rsvp',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/events/rsvp')
    },

    {
        path: '/surveys',
        name: 'surveys',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/surveys')
    },
    {
        path: '/surveys/new',
        name: 'new-survey',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/surveys/new')
    },
    {
        path: '/surveys/:id/edit',
        name: 'survey-edit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/surveys/new')
    },
    {
        path: '/surveys/:id',
        name: 'survey-detail',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/surveys/detail')
    },
    {
        path: '/survey/:shortlink',
        name: 'answer-survey',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/surveys/answer')
    },
    {
        path: '/invitation/:id/:answer',
        name: 'invitation-response',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/invitations/response')
    },
    {
        path: '/survey/:shortlink/answer',
        name: 'survey-answer',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/surveys/response')
    },

]
