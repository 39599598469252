import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
// import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import VueTheMask from 'vue-the-mask';
// import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import VueUploadComponent from 'vue-upload-component';

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import CustomHelpers from "./helpers/custom";
import "@/assets/scss/app.scss";

// Datepicker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

// Checkbox table
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'


Vue.prototype.$appDomain = "https://app.galifopartners.com/";
Vue.prototype.$apiDomain = "https://api.galifopartners.com/";

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(DatePicker)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(VueTheMask)
// Vue.use(require('vue-chartist'))
Vue.component('file-upload', VueUploadComponent);
Vue.use(CustomHelpers)
Vue.use(VueGoodTablePlugin);

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
//     libraries: 'places',
//   },
//   installComponents: true
// })

// Vue.component('apexchart', VueApexCharts)



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
