import axios from 'axios';
import router from '@/router';


export const HTTP = axios.create({
    // baseURL: 'http://localhost:8080/',
    baseURL: 'https://api.galifopartners.com/',
});

let token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null;


if (token) {
    HTTP.defaults.headers.common.Authorization = 'Bearer ' + token;
}





HTTP.interceptors.response.use(function (response) {

    return response;
}, function (error) {

    if (error.response) {
        if (error.response.status == 401) {
            localStorage.removeItem('user');
            HTTP.defaults.headers.common.Authorization = '';

            router.push({
                name: 'login',
            });
        }
    }
    return Promise.reject(error);
});